import HomePage from "./pages/HomePage";
import "./index.css"

function App() {
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
