import React from "react";

// Sample data structure for terms
const terms = [
  {
    english: "Absorber, (shock) damper",
    russian: "амортизатор",
    uzbek: "amartizator, metall klapon",
  },
  {
    english: "accelerator (BrE)",
    russian: "акселератор, педал 'газа'",
    uzbek: "pedal, gaz pedali",
  },
  {
    english: "accessory drive belt",
    russian: "приводной ремень",
    uzbek: "qo’shimcha haydovchi kamari",
  },
  {
    english: "aerial (BrE)",
    russian: "антенна",
    uzbek: "Antenna",
  },
  {
    english: "air bag",
    russian: "подушка безопасности",
    uzbek: "xavfizlik yostig’i",
  },
  {
    english: "air cleaner",
    russian: "воздушный фильтр",
    uzbek: "havo tozolovchi (filtr)",
  },
  {
    english: "air conditioner",
    russian: "кондиционер",
    uzbek: "Konditsioner",
  },
  {
    english: "air duct",
    russian: "воздуховод",
    uzbek: "havo kanali",
  },
  {
    english: "air mass meter",
    russian: "датчик количества воздуха",
    uzbek: "havo massasi o’lchagich",
  },
  {
    english: "air (надувная) подушка безопасности",
    russian: "подушка безопасности",
    uzbek: "xavfsizlik yostiqchasi",
  },
  {
    english: "aircleaner",
    russian: "воздухоочиститель; воздушный фильтр",
    uzbek: "havo tozalovchi",
  },
  {
    english: "airfilter",
    russian: "воздухоочиститель; воздушный фильтр",
    uzbek: "havo filteri",
  },
  {
    english: "airfuelratio",
    russian: "состав топлива",
    uzbek: "yoqilg’i tarkibi",
  },
  {
    english: "airoutlet",
    russian: "воздуховыпускное отверстие (в системе кондиционирования воздуха)",
    uzbek: "havo chiqish tizimi",
  },
  {
    english: "alarm system for road vehicle",
    russian: "охранная система",
    uzbek: "xavfsizlik tizimi",
  },
  {
    english: "alternator",
    russian: "генератор",
    uzbek: "to’k o’zgartiruvchi generator",
  },
  {
    english: "altitude",
    russian: "высота",
    uzbek: "Balandlik",
  },
  {
    english: "antenna (AmE)",
    russian: "антенна",
    uzbek: "Antenna",
  },
  {
    english: "anti lock Brake System",
    russian: "антиблокировочная система тормозов",
    uzbek: "Tormoz sistemasi qulflanib qolishiga qarshi tizim",
  },
  {
    english: "anti-roll bar",
    russian: "стабилизатор",
    uzbek: "amartizator qismi",
  },
  {
    english: "armrest",
    russian: "подлокотник",
    uzbek: "tirsak qo‘yish uchun moslama",
  },
  {
    english: "auto (fml, espAmE) = car",
    russian: "автомобиль",
    uzbek: "avto ulov, mashina",
  },
  {
    english: "automatic gearbox",
    russian: "автоматическая трансмиссия",
    uzbek: "avtomat transmissiya",
  },
  {
    english: "axle system",
    russian: "мост",
    uzbek: "g’ildirak o’qi",
  },
  {
    english: "Backseat",
    russian: "заднее сидение",
    uzbek: "orqa o’rindiq",
  },
  {
    english: "back-up lights / reversing lights",
    russian: "задние фары",
    uzbek: "orqa fara (chiroq)",
  },
  {
    english: "backup",
    russian: "задний ход",
    uzbek: "orqaga tisarilish (haydash)",
  },
  {
    english: "barometric pressure",
    russian: "барометрическое давление",
    uzbek: "barometrik bosim",
  },
  {
    english: "battery",
    russian: "аккумулятор",
    uzbek: "akkumulyator, batareya, quvvat",
  },
  {
    english: "bead chafer",
    russian: "внутренний защитный слой борта шины",
    uzbek: "shinalarning ichki himoya qatlami",
  },
  {
    english: "beam",
    russian: "1) балансир 2) балка; коромысло",
    uzbek: "1) muvozanat  2) nur",
  },
  {
    english: "bed",
    russian: "кузов (грузовика)",
    uzbek: "yuk mashinasi kuzovi",
  },
  {
    english: "bedliner",
    russian: "защитное покрытие кузова грузовика",
    uzbek: "yuk mashina tanasini himoya qiluvchi",
  },
  {
    english: "belt",
    russian: "ремень",
    uzbek: "kamar, remen",
  },
  {
    english: "beltline",
    russian: "линия пояса",
    uzbek: "Chiziq",
  },
  {
    english: "blinker (AmE, infml) / indicator",
    russian: "указатель поворота",
    uzbek: "burilishlani ko’rsatib turuvchi moslama /chiroq",
  },
  {
    english: "block",
    russian: "преграда; препятствие",
    uzbek: "to’siq",
  },
  {
    english: "blower motor",
    russian: "обогреватель",
    uzbek: "Isitgich",
  },
  {
    english: "body piles",
    russian: "корпус стойки",
    uzbek: "tana korpusi",
  },
  {
    english: "body side moulding",
    russian: "молдинг",
    uzbek: "Qolip",
  },
  {
    english: "body side molding",
    russian: "молдинг на боковине кузова",
    uzbek: "tananing yon tomonlari",
  },
  {
    english: "bolt join",
    russian: "болтовое соединение",
    uzbek: "murvatli (bolt) ulanish",
  },
  {
    english: "bonnet (BrE) / hood",
    russian: "капот",
    uzbek: "mashina kapoti",
  },
  {
    english: "boost",
    russian: "повышение; усиление",
    uzbek: "ko’tarilish, kuchayish",
  },
  {
    english: "boot lid",
    russian: "крышка багажника",
    uzbek: "bagaj qopqog’i",
  },
  {
    english: "brake caliper",
    russian: "суппорт дискового тормоза",
    uzbek: "diskli tormoz kaliperi",
  },
  {
    english: "brake drum",
    russian: "тормозной барабан",
    uzbek: "tormoz barabani",
  },
  {
    english: "brake fluid",
    russian: "тормозная жидкость",
    uzbek: "tormoz moyi",
  },
  {
    english: "brake fluid reservoir",
    russian: "бачок для тормозной жидкости",
    uzbek: "tormoz moyi uchun bachok",
  },
  {
    english: "brake light",
    russian: "стоп-сигнал",
    uzbek: "tormoz chirog’i",
  },
  {
    english: "brake master cylinder",
    russian: "главный тормозной цилиндр",
    uzbek: "asosiy tormoz silindiri",
  },
  {
    english: "brake pad",
    russian: "тормозная колодка",
    uzbek:
      "kolodka (tormoz bosilganda mashina diskiga ishqalanib mashinani to’xtatadigan metal)",
  },
  {
    english: "brake rotor (AmE)",
    russian: "тормозной диск (AmE)",
    uzbek: "tormoz parragi",
  },
  {
    english: "brake servo",
    russian: "усилитель тормоза",
    uzbek: "tormoz kuchaytirgich",
  },
  {
    english: "brake",
    russian: "тормоз",
    uzbek: "Tormoz",
  },
  {
    english: "brake disk",
    russian: "тормозной диск",
    uzbek: "tormoz diski",
  },
  {
    english: "braking system",
    russian: "тормозная система",
    uzbek: "tormoz tizimi",
  },
  {
    english: "breaker",
    russian: "выключатель; прерыватель",
    uzbek: "tok uzib qo'yuvchi",
  },
  {
    english: "buckle (up)",
    russian: "пристегнуться (ремнем безопасности)",
    uzbek: "kamarni bog’lash",
  },
  {
    english: "buckle",
    russian: "скоба, хомут, изгиб, коробится, выгибать",
    uzbek: "Qisqich",
  },
  {
    english: "bulb",
    russian: "лампа накаливания",
    uzbek: "Lampa",
  },
  {
    english: "bumper",
    russian: "бампер, буфер",
    uzbek: "Bamper",
  },
  {
    english: "bumper face",
    russian: "бампер",
    uzbek: "Bamper",
  },
  {
    english: "bumper molding",
    russian: "формованное изделие бампера",
    uzbek: "bamper ramkasi (devori)",
  },
  {
    "english": "Caliper",
    "russian": "суппорт (тормозной)",
    "uzbek": "klapon, zaslonka (tebranishni pasaytiruvchi asbob)"
  },
  {
    "english": "cam",
    "russian": "кулачок",
    "uzbek": "asboblar paneli"
  },
  {
    "english": "camber",
    "russian": "угол развала",
    "uzbek": "oynalarni parlanishdan tozalovchi asbob"
  },
  {
    "english": "camshaft",
    "russian": "распредвал",
    "uzbek": "dizelli motor"
  },
  {
    "english": "cap plies",
    "russian": "колпак; крышка, слой",
    "uzbek": "kuzovdagi pachoq"
  },
  {
    "english": "Dipped beam",
    "russian": "ближний свет фар",
    "uzbek": "yaqinni yorituvchi chiroq"
  },
  {
    "english": "disc brake",
    "russian": "дисковый тормоз",
    "uzbek": "diskli tormoz"
  },
  {
    "english": "distributor",
    "russian": "распределител",
    "uzbek": "o’t olish svichasiga to’k yetkazib beruvchi moslama, distribyutor"
  },
  {
    "english": "doors",
    "russian": "двери",
    "uzbek": "Eshik"
  },
  {
    "english": "Edge cover",
    "russian": "угловая накладка",
    "uzbek": "burchakli qoplama"
  },
  {
    "english": "engine cradle (sub",
    "russian": "рама фундамента двигателя",
    "uzbek": "motor poydevori ramasi"
  },
  {
    "english": "engine flywheel",
    "russian": "маховик двигателя",
    "uzbek": "motorning zalvor to‘g‘inli po‘lat yoki cho‘yan g‘ildiragi"
  },
  {
    "english": "exhaust pipe",
    "russian": "выхлопная труба",
    "uzbek": "mashinaning tutun chiqadigan qismi"
  },
  {
    "english": "exhaust valve",
    "russian": "выпускной клапан",
    "uzbek": "gaz chiqish klapani"
  },
  {
    "english": "Fame",
    "russian": "кузов",
    "uzbek": "Kuzov"
  },
  {
    "english": "fan",
    "russian": "вентилятор",
    "uzbek": "ventilyator (yelpugich)"
  },
  {
    "english": "fenders",
    "russian": "крыло",
    "uzbek": "balon tepasidagi himoya qismi"
  },
  {
    "english": "fog headlamp",
    "russian": "противотуманная фара",
    "uzbek": "tumanga qarshi fara"
  },
  {
    "english": "four wheel drive system",
    "russian": "полный привод",
    "uzbek": "to’rt g’ildirakli tizim"
  },
  {
    "english": "front wheel drive",
    "russian": "передний привод",
    "uzbek": "old uzatmali tizim"
  },
  {
    "english": "front suspension",
    "russian": "подвеска передних колёс; передняя подвеска",
    "uzbek": "old podveska"
  },
  {
    "english": "fuel injection",
    "russian": "впрыск топлива",
    "uzbek": "yoqilg’ini purkab berish"
  },
  {
    "english": "fuel pump",
    "russian": "топливный насос",
    "uzbek": "yoqilg’i nasosi"
  },
  {
    "english": "Gear shift lever",
    "russian": "рычаг коробки преключения передач",
    "uzbek": "uzatma qutisi dastagi"
  },
  {
    "english": "glove compartment",
    "russian": "вещевой ящик",
    "uzbek": "mashina ichki tortma qutisi (bardachok)"
  },
  {
    "english": "grilles",
    "russian": "облицовка радиатора",
    "uzbek": "radiator himoya temir panjarasi, to’siq"
  },
  {
    "english": "Hand pump",
    "russian": "ручной насос",
    "uzbek": "qo’l nasosi"
  },
  {
    "english": "header or nose panels",
    "russian": "передние панели кузова",
    "uzbek": "kuzovning old paneli"
  },
  {
    "english": "headlights",
    "russian": "передние фары",
    "uzbek": "fara, fonar, fonus"
  },
  {
    "english": "high-pressure fuel injection pump",
    "russian": "топливный насос высокого давления",
    "uzbek": "yuqori bosimli yonilg’i yetkazib berish nasosi"
  },
  {
    "english": "hoods",
    "russian": "капот",
    "uzbek": "mashinaning old kapoti"
  },
  {
    "english": "horn",
    "russian": "звуковой сигнал",
    "uzbek": "mashina signali"
  },
  {
    "english": "Ignition coil",
    "russian": "катушка зажигания",
    "uzbek": "o’t oldirish simi"
  },
  {
    "english": "ignition distributor",
    "russian": "распределител зажигания",
    "uzbek": "o’t olish svichasiga tok yetkazib beruvchi moslama"
  },
  {
    "english": "ignition",
    "russian": "зажигание",
    "uzbek": "o't oldirish"
  },
  {
    "english": "inner liner",
    "russian": "внутренняя облицовка; внутренняя обшивка; футеровка",
    "uzbek": "ichki qoplama"
  },
  {
    "english": "inner tie rod",
    "russian": "внутренняя рулевая тяга",
    "uzbek": "rulning ichki tayoqchasi (tyaga)"
  },
  {
    "english": "inside rear",
    "russian": "внутрисалонное зеркало заднего вида",
    "uzbek": "orqani ko’rish uchun ichki oyna"
  },
  {
    "english": "instrumental panel",
    "russian": "панел прибов",
    "uzbek": "asboblar paneli"
  },
  {
    "english": "intake port",
    "russian": "впускной канал; впускное окно; впускное отверсти",
    "uzbek": "kirish kanali"
  },
  {
    "english": "intake valve",
    "russian": "впускной [всасывающий] клапан",
    "uzbek": "kirish klapini"
  },
  {
    "english": "Lifting force",
    "russian": "подъемная сила",
    "uzbek": "tepalik kuch"
  },
  {
    "english": "lifting jack",
    "russian": "домкрат",
    "uzbek": "Domkrat"
  },
  {
    "english": "Main beam",
    "russian": "далний свет фар",
    "uzbek": "uzoqni yoritish chirog'i"
  },
  {
    "english": "manual gearbox",
    "russian": "механическaя коробка преключения передач",
    "uzbek": "mexanik uzatmalar qutisi"
  },
  {
    "english": "mirrors",
    "russian": "зеркало",
    "uzbek": "abtomobilning orqani ko'rsatuvchi yon oynalari"
  },
  {
    "english": "mud flap",
    "russian": "брызговик",
    "uzbek": "loy sachrashini oldini olish uchun mashina g’ildiragini ortiga qo’yiladigan mato"
  },
  {
    "english": "muffler (on undercarriage)",
    "russian": "глушител",
    "uzbek": "mashinaning shovqinini kamaytiruvchi tarkibiy qism (glushitel)"
  },
  {
    "english": "Number plate",
    "russian": "регистрatsiонный знак",
    "uzbek": "davlat raqam belgisi"
  },
  {
    "english": "Odometer",
    "russian": "одометр",
    "uzbek": "odometer (bosib o’tilgan masofani ko’rsatadigan asbob)"
  },
  {
    "english": "outside rear",
    "russian": "наружное зеркало заднего вида",
    "uzbek": "tashqi orqa ko’rish oynasi"
  },
  {
    "english": "Parking brake",
    "russian": "стояночный тормоз",
    "uzbek": "qo'l tormozi (ruchnoy)"
  },
  {
    "english": "parking light",
    "russian": "габаритный огон",
    "uzbek": "mashina asosiy fanarining yonidagi kichik toritgich"
  },
  {
    "english": "passenger compartment",
    "russian": "салон",
    "uzbek": "yo’lovchi saloni"
  },
  {
    "english": "piston",
    "russian": "поршен; плунжер",
    "uzbek": "Porshen"
  },
  {
    "english": "power steeringreservoir",
    "russian": "бачок усилителя рулевого механизма",
    "uzbek": "rul mexanizmini kuchaytirgich idishi (bachogi)"
  },
  {
    "english": "power",
    "russian": "мощност",
    "uzbek": "quvvat, kuch, energiya"
  },
  {
    "english": "Radiator supports",
    "russian": "опорная стойка радиатора",
    "uzbek": "radiotorni himoya ustuni"
  },
  {
    "english": "radiators",
    "russian": "радиаторы",
    "uzbek": "radiator (motor suvini sovutadigan moslama)"
  },
  {
    "english": "rear axle",
    "russian": "1) задний мост 2) задняя ос",
    "uzbek": "orqa g’ildirak o’qi"
  },
  {
    "english": "rear suspension",
    "russian": "подвеска задних колёс",
    "uzbek": "orqa podveska"
  },
  {
    "english": "rear",
    "russian": "задний привод",
    "uzbek": "orqa g’ildirak uzatmasi"
  },
  {
    "english": "reflector",
    "russian": "отражател",
    "uzbek": "nur qaytargich"
  },
  {
    "english": "reservetank",
    "russian": "дополнителный бачок",
    "uzbek": "qo’shimcha bachok"
  },
  {
    "english": "rib",
    "russian": "ребро",
    "uzbek": "kuzov qovurg'asi"
  },
  {
    "english": "rings",
    "russian": "колцо",
    "uzbek": "halqa, aylana (mahkamlash uchun)"
  },
  {
    "english": "Sealed beam headlight",
    "russian": "герметизированная фара",
    "uzbek": "germetik fara"
  },
  {
    "english": "sidelight(parking light)",
    "russian": "боковой свет",
    "uzbek": "mashinaning yon chirog’i"
  },
  {
    "english": "sideway",
    "russian": "боковина шины",
    "uzbek": "shinani yon devori"
  },
  {
    "english": "spare wheel",
    "russian": "запасное колесо",
    "uzbek": "zaxiradagi g’ildirak"
  },
  {
    "english": "spark plug",
    "russian": "свеча зажигания",
    "uzbek": "o’t oldiruvchi svicha"
  },
  {
    "english": "speedometer",
    "russian": "спидометр",
    "uzbek": "tezlik o’lchagich"
  },
  {
    "english": "spring",
    "russian": "рессора",
    "uzbek": "prujina, ressora"
  },
  {
    "english": "stabilizerbar",
    "russian": "балка стабилизатора",
    "uzbek": "stabilizator balkasi"
  },
  {
    "english": "steering gear",
    "russian": "рулевой механизм",
    "uzbek": "rul mexanizmi"
  },
  {
    "english": "steering wheel",
    "russian": "руловое колесо",
    "uzbek": "mashina ruli"
  },
  {
    "english": "step bumpers",
    "russian": "задний бампер",
    "uzbek": "orqa bamper"
  },
  {
    "english": "stop light",
    "russian": "стоп-сигнал",
    "uzbek": "Svetofor"
  },
  {
    "english": "sun roof",
    "russian": "люк",
    "uzbek": "mashina tom qismidagi oyna (lyuk)"
  },
  {
    "english": "sun visor",
    "russian": "солнезащитный козырок",
    "uzbek": "mashina old oynasining quyosh nurini to’sib turadigan ko’tarilib tushiradigan qismi"
  },
  {
    "english": "supercharger",
    "russian": "механический нагнетател",
    "uzbek": "ichki yonish dvigateldagi havo yoqilg'i aralashmasining bosimini oshiruvchi qurilma"
  },
  {
    "english": "Tachometer",
    "russian": "тахометр",
    "uzbek": "taxometr (aylanish tezligini o’chaydigan asbob)"
  },
  {
    "english": "tail lights",
    "russian": "задняя фара",
    "uzbek": "mashina orqasidagi chiroqlar"
  },
  {
    "english": "tailgates (trunk)",
    "russian": "задний откидной борт (багажник )",
    "uzbek": "orqa bagaj eshigi"
  },
  {
    "english": "thermostat",
    "russian": "термостат",
    "uzbek": "Termostat"
  },
  {
    "english": "tie rod end",
    "russian": "наконечник рулевой тяги",
    "uzbek": "rul tayoqchasi uchi"
  },
  {
    "english": "timing belt",
    "russian": "ремен привода газораспределителного механизма",
    "uzbek": "gaz taqsimlash mexanizmi tasmasi (zubchatka)"
  },
  {
    "english": "torque",
    "russian": "крутящий момент",
    "uzbek": "aylantiruvchi kuch"
  },
  {
    "english": "transmission cooler",
    "russian": "охладител масла коробки передач",
    "uzbek": "uzatma qutisini sovituvchi moslama"
  },
  {
    "english": "transmission",
    "russian": "трансмиссия",
    "uzbek": "uzatma qutisi mexanizmi"
  },
  {
    "english": "tread",
    "russian": "1) колея 2) протектор (шины)",
    "uzbek": "g’ildirak tishi"
  },
  {
    "english": "trunk-boot",
    "russian": "bagaj (yuk qo’yish joyi)",
    "uzbek": ""
  },
  {
    "english": "turbine",
    "russian": "турбина",
    "uzbek": "suv, bug’, havo yoki gaz kuchi bilan ishlaydigan dvigatel"
  },
  {
    "english": "tyre (tire)",
    "russian": "шина",
    "uzbek": "shina, balon, pokrishka"
  },
  {
    "english": "Unitized body",
    "russian": "несущий кузов",
    "uzbek": "ko’tarib turuvchi kuzovni"
  },
  {
    "english": "upper hose",
    "russian": "верхний шланг",
    "uzbek": "yuqori shlang"
  },
  {
    "english": "Valves",
    "russian": "клапан",
    "uzbek": "ventil, jumrak, klapin"
  },
  {
    "english": "viscosity",
    "russian": "вязкост",
    "uzbek": "yopishqoqlik darajasi"
  },
  {
    "english": "void ratio",
    "russian": "пустота",
    "uzbek": "bo’shliq"
  },
  {
    "english": "Water pump",
    "russian": "водяной насос",
    "uzbek": "suv haydagich (nasos)"
  },
  {
    "english": "wheel brace",
    "russian": "баллонный ключ",
    "uzbek": "g’ildirak mahkamlagich (mahkamlash uchun klyuch)"
  },
  {
    "english": "wheel brake drum",
    "russian": "тормозной барабан",
    "uzbek": "g'ildirak tormozi barabani"
  },
  {
    "english": "wheel camber",
    "russian": "развал колес",
    "uzbek": "shinalarning ikki yon tomonga og’ish burchagi"
  },
  {
    "english": "wheel cover",
    "russian": "декоративный колпак колеса",
    "uzbek": "g’ildirakning bezak uchun qopqog’i"
  },
  {
    "english": "wheel nut",
    "russian": "гайка",
    "uzbek": "g’ildirak gaykasi (bolt)"
  },
  {
    "english": "wheel spindle",
    "russian": "ос колеса",
    "uzbek": "g’ildirak o’qi"
  },
  {
    "english": "wheelbase",
    "russian": "колесная база",
    "uzbek": "old va orqa g’ildirak orasidagi masofa"
  },
  {
    "english": "wheel",
    "russian": "колесо",
    "uzbek": "g’ildirak ,balon"
  },
  {
    "english": "windscreen wiper",
    "russian": "стеклоочистител",
    "uzbek": "old oyna tozalagich"
  },
  {
    "english": "windshield(windscreen)",
    "russian": "переднее стекло",
    "uzbek": "mashina old himoya oynasi"
  },
  {
    "english": "wind-up window",
    "russian": "механический стеклоподъемник",
    "uzbek": "oyna ko’tarish mexanizmi"
  },
  {
    "english": "wing(fender)",
    "russian": "крыло",
    "uzbek": "g’ildirak tepasidagi mashina qanoti"
  },
  {
    "english": "wishbone suspesion",
    "russian": "рычажная подвеска",
    "uzbek": "richagli podveska (pishangli ilgak)"
  },
  {
    "english": "wristpin",
    "russian": "1) поршневой палец 2) цапфа",
    "uzbek": "porshen pistoni"
  },
  {
    "english": "Xenon head lamp",
    "russian": "фара с ксеноновым светом",
    "uzbek": "Xenon lampali yoritgich"
  },
  {
    "english": "xl ( extra load) tyre",
    "russian": "xl шина",
    "uzbek": "Bosimga chidamliligi kuchaytirilgan shina"
  }

  // Add more terms as needed
];

function HomePage() {
  return (
    <div className="bg-[#CFE6FE] w-full min-h-screen py-1 flex flex-col items-center px-2 pb-8">
      <h1 className="text-center font-sans text-lg font-bold text-[#330099] mt-8 mb-4">
        Английский словарь автомобильных терминов  <span className="text-blue-500 ml-3">(by Yuldasheva Mastona)</span>
      </h1>
      <div
        className="flex items-center justify-center text-[#0000FF] font-bold mt-4 flex-wrap"
        id="AlphabetMap"
      >
        {Array.from({ length: 26 }, (_, index) => {
          const letter = String.fromCharCode(65 + index); // A-Z letters
          return (
            <React.Fragment key={index}>
              <a className="underline" href={`#${letter}section`}>
                {letter}
              </a>
              {index !== 25 && <span className="text-black mx-1"> - </span>}
            </React.Fragment>
          );
        })}
        <span className="text-black"> - </span>
        <a className="underline" href="#prim">
          Примечание
        </a>
      </div>
      <div className="flex flex-col items-center mx-auto mt-8 w-full px-4 sm:px-8">
        {terms.map((term, index) => (
          <div key={index} className="w-full max-w-4xl">
            {/* Render the 'A' heading only for terms starting with 'A' */}
            {term.english.startsWith("A") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Asection"
              >
                A
              </p>
            )}
            {term.english.startsWith("B") && (
              <p
                className="text-red-500 font-bold text-xs lg:stext-[14px] mt-5"
                id="Bsection"
              >
                B
              </p>
            )}
            {term.english.startsWith("C") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Csection"
              >
                C
              </p>
            )}
             {term.english.startsWith("D") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Dsection"
              >
                D
              </p>
            )} 
             {term.english.startsWith("E") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Esection"
              >
                E
              </p>
            )}
             {term.english.startsWith("Q") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Qsection"
              >
                Q
              </p>
            )}
             {term.english.startsWith("W") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Wsection"
              >
                W
              </p>
            )}
             {term.english.startsWith("R") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Rsection"
              >
                R
              </p>
            )}
             {term.english.startsWith("T") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Tsection"
              >
                T
              </p>
            )}
             {term.english.startsWith("Y") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Ysection"
              >
                Y
              </p>
            )}
             {term.english.startsWith("U") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Usection"
              >
                U
              </p>
            )}
             {term.english.startsWith("I") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Isection"
              >
                I
              </p>
            )}
             {term.english.startsWith("O") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Osection"
              >
                O
              </p>
            )}
             {term.english.startsWith("P") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Psection"
              >
                P
              </p>
            )}
             {term.english.startsWith("S") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Ssection"
              >
                S
              </p>
            )}
             {term.english.startsWith("F") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Fsection"
              >
                F
              </p>
            )}
             {term.english.startsWith("G") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Gsection"
              >
                G
              </p>
            )}
             {term.english.startsWith("H") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Hsection"
              >
                H
              </p>
            )}
             {term.english.startsWith("J") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Jsection"
              >
                J
              </p>
            )}
             {term.english.startsWith("K") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Ksection"
              >
                K
              </p>
            )}
             {term.english.startsWith("L") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Lsection"
              >
                L
              </p>
            )}
             {term.english.startsWith("Z") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Zsection"
              >
                Z
              </p>
            )}
             {term.english.startsWith("X") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Xsection"
              >
                X
              </p>
            )}
             {term.english.startsWith("V") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Vsection"
              >
                V
              </p>
            )}
             {term.english.startsWith("N") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Nsection"
              >
                N
              </p>
            )}
             {term.english.startsWith("M") && (
              <p
                className="text-red-500 font-bold text-xs lg:text-[14px] mt-5"
                id="Msection"
              >
                M
              </p>
            )}
            {/* Example term */}
            <div className="flex flex-wrap justify-start items-center mt-2">
              <span className=" text-[#009999] font-bold text-[8px] lg:text-[14px] font-sans">
                {term.english}
              </span>
              <span className="mx-2"> - </span>
              <span className=" text-blue-500 text-[8px] lg:text-[14px] font-sans">
                {term.russian}
              </span>
              <span className="mx-2"> - </span>
              <span className=" text-[8px] lg:text-[14px] font-sans">{term.uzbek}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
